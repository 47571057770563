body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-feature-settings: "kern", "liga", "pnum";
  font-size: 14px;
  font-weight: 400;
  line-height: 1.464285;
  color: rgba(0,0,0,0.87)
}

p {
  margin: 0;
}
